import './src/styles/global.css';

export const onInitialClientRender = () => {
  if (window.location.pathname === '/') {
    function initApollo() {
      var n = Math.random().toString(36).substring(7),
        o = document.createElement('script');
      o.src = 'https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=' + n;
      o.async = true;
      o.defer = true;
      o.onload = function () {
        window.trackingFunctions.onLoad({ appId: '665e107eb1b4ae06861bf90a' });
      };
      document.head.appendChild(o);
    }
    initApollo();
  }
};
